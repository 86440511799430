import React from 'react';

import Button from '../../../components/Button/button';

import Impressora from '../../../assets/images/impressora.png';
import Estamparia from '../../../assets/images/estamparia.png';

import './area.scss';

const AreaAtuacao = () => (
  <>
    <section id="area-atuacao" className="container">
      <div className="title-area">
        <h1>Áreas de atuação</h1>
        <p>
          Nós criamos experiências únicas em cada metro de tecido impresso,
          trazendo sempre o melhor da sublimação em cada serviço realizado
        </p>
      </div>
      <div className="impressao-digital">
        <div className="text-wrapper">
          <h1>
            Impressão digital <br />
            sublimática
          </h1>
          <p>
            Com impressora Epson de alta produtividade, fornecemos o papel para
            o transfer sublimático para grandes tiragens.
          </p>
          <Button link="/estamparia-digital" className="button-area" />
        </div>
        <div className="img-wrapper">
          <img src={Impressora} />
        </div>
      </div>
      <div className="estamparia-rolo">
        <div className="img-wrapper">
          <img src={Estamparia} />
        </div>
        <div className="text-wrapper">
          <h1>Estamparia em rolo</h1>
          <p>
            Pelo método da sublimação, estampamos a imagem desejada em rolos
            através de calandra de alta performance, garantindo a satisfação no
            resultado.
          </p>
          <Button link="/estamparia-digital" className="button-area" />
        </div>
      </div>
    </section>
  </>
);

export default AreaAtuacao;
