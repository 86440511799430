import React from 'react';

import AtendimentoImage from '../../../assets/images/atendimento.png';

import './atendimento.scss';

const Atendimento = () => (
  <>
    <section id="atendimento" className="container">
      <div className="title-atendimento">
        <h1>Atendimento exclusivo</h1>
      </div>
      <div className="image-atendimento">
        <img src={AtendimentoImage} />
      </div>
    </section>
  </>
);

export default Atendimento;
