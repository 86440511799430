import React from 'react';
import WhatsApp from '../../assets/images/whats.png';

import './whatsapp.scss';

const Button = () => (
  <>
    <section id="whatsapp">
      <a
        href="https://api.whatsapp.com/send?phone=5511982043619&text=Ol%C3%A1%20Sedafio%2C%20estou%20com%20uma%20d%C3%BAvida!"
        target="_blank"
      >
        <img src={WhatsApp} />
      </a>
    </section>
  </>
);

export default Button;
