import React, { Component } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './carrousel.scss';

import Cliente1 from '../../../assets/images/cliente1.png';
import Cliente2 from '../../../assets/images/cliente2.png';
import Cliente3 from '../../../assets/images/cliente3.png';
import Cliente4 from '../../../assets/images/cliente4.png';

export default class Carrousel extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 400,
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <section id="carrousel-marcas" className="container">
        <div className="title-atendimento">
          <h1>Nossos Clientes</h1>
        </div>
        <Slider {...settings}>
          <div className="item-marca">
            <img src={Cliente1} />
          </div>
          <div className="item-marca">
            <img src={Cliente2} />
          </div>
          <div className="item-marca">
            <img src={Cliente3} />
          </div>
          <div className="item-marca">
            <img src={Cliente4} />
          </div>
          <div className="item-marca">
            <img src={Cliente1} />
          </div>
          <div className="item-marca">
            <img src={Cliente2} />
          </div>
          <div className="item-marca">
            <img src={Cliente3} />
          </div>
          <div className="item-marca">
            <img src={Cliente4} />
          </div>
        </Slider>
      </section>
    );
  }
}
