import React from 'react';
import Vestido from '../../../assets/images/vestido-home.png';
import BannerBloco1 from '../../../assets/images/banner-bloco1.png';

import Button from '../../../components/Button/button';

import './bloco1.scss';

const Bloco1 = () => (
  <>
    <section id="imp-digital" className="container">
      <div className="text-img-digital">
        <h1>
          Impressão digital <br /> e estamparia
        </h1>
      </div>
      <div className="image-img-digital">
        <img src={Vestido} />
      </div>
    </section>
    <section id="text-block" className="container">
      <div className="image-banner">
        <img src={BannerBloco1} />
      </div>
      <div className="text-banner">
        <p>
          A Sedafio nasceu com a missão de transformar felicidade e sonhos em
          estampas. Há dois anos vêm crescendo diariamente, com o objetivo de se
          tornar uma das melhores empresas na área de estamparia e
          personalização de tecidos. Prezando sempre pelo atendimento exclusivo
          e com a satisfação dos nossos parceiros.
        </p>
        <Button link="/sobre-nos" />
      </div>
    </section>
  </>
);

export default Bloco1;
