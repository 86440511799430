import React from 'react';

import { SEO } from '../../components/seo';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import WhatsApp from '../../components/whatsapp';

import Bloco1 from './bloco1';
import AreaAtuacao from './area';
import Atendimento from './atendimento';
import Carrousel from './carrousel';
import '../../reset.css';

const Home = () => (
  <>
    <SEO title="Sedafio" />
    <WhatsApp />
    <Header />
    <Bloco1 />
    <AreaAtuacao />
    <Atendimento />
    <Carrousel />
    <Footer />
  </>
);

export default Home;
