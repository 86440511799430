import React from 'react';
import { Link } from 'gatsby';

import Seta from '../../assets/images/seta.png';

import './button.scss';

const Button = props => (
  <>
    <section id="button-padrao">
      <Link to={props.link}>
        SAIBA MAIS <img src={Seta} />
      </Link>
    </section>
  </>
);

export default Button;
